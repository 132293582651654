import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranscripts } from './TranscriptContext';
import './ConversationUI.css';
import PersonIcon from '@mui/icons-material/Person';
import nl2br from 'react-nl2br';
import  Avatar  from '@mui/material/Avatar';
import { Tooltip, IconButton } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Chip from '@mui/material/Chip';
import { AppBar } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';
import TopBar from './TopBar';







function TranscriptPage() {
 

    
    const navigate = useNavigate();
    const {saveTranscript} = useTranscripts();
    const [showCopyIcon, setShowCopyIcon] = useState(null);
    const messagesEndRef = React.useRef(null);
    const location = useLocation();
    const messages = location.state?.messages;
    const caseId = location.state?.case;
    const role = location.state?.role;


  const handleCopyMessage = (content) => {
    navigator.clipboard.writeText(content).then(() => {
        // Handle success, maybe display a small toast or tooltip to the user.
        console.log("Text copied successfully!");
    }).catch((err) => {
        console.error("Error copying text: ", err);
    });
};

  const saveCurrentTranscript = () => {
    saveTranscript(messages, caseId, role);
    // Navigate to the saved transcripts list
    navigate("/saved-transcripts");
  };

  const getImageStyle = (avatarName) => {
    switch (avatarName) {
      case 'Justice Roberts':
        return {
            transform: 'scale(.45)',
            position: 'relative',
            top: '15%'
            
        };
      case 'Justice Kagan':
        return {
          position: 'relative',
          transform: 'scale(.45)',
          left: '7%',
          top: '15%',
        };
        case 'Justice Alito':
            return {
              position: 'relative',
              transform: 'scale(.45)',
          
              top: '17%',
            };
        case 'Justice Gorsuch':
            return {
              position: 'relative',
              transform: 'scale(.43)',
              left: '2%',
              top: '15%',
            };
            case 'Judge Tatel':
              return {
                position: 'relative',
                transform: 'scale(.7)',
              };
          case 'Judge Millett':
              return {
                position: 'relative',
                transform: 'scale(.7)',
              };
          case 'Judge Sentelle':
              return {
                position: 'relative',
                transform: 'scale(.7)',
  
              };
          case 'Judge Henderson':
              return {
                position: 'relative',
                transform: 'scale(.7)',
              };
          case 'Judge Rogers':
              return {
                position: 'relative',
                transform: 'scale(.7)',
              };
          case 'Judge Griffith':
              return {
                position: 'relative',
                transform: 'scale(.7)',
              };
          case 'Judge Pillard':
              return {
                position: 'relative',
                transform: 'scale(.7)',
              };
          case 'Judge Wilkins':
              return {
                position: 'relative',
                transform: 'scale(.7)',
              };
          case 'Judge Garland':
              return {
                position: 'relative',
                transform: 'scale(.7)',
              };
          case 'Judge Brown':
              return {
                position: 'relative',
                transform: 'scale(.7)',
              };
          case 'Judge Ginsburg':
              return {
                position: 'relative',
                transform: 'scale(.7)',
              };
    
      // add more cases as needed
      default:
        return {
            transform: 'scale(.45)',
            position: 'relative',
            top: '15%'
    
         };
    }
  };


  return (

        <div style={{display: 'flex', flexDirection: 'column', flex: 1}} >
                      <div style={{ display: 'flex', flex: 1 }}>


<TopBar/>
      <div className="c-stv-convo-container" style={{flexGrow: 1}}>
      <Toolbar
            sx={{
       
                position: 'fixed',
                top: '64px',
                width: '100%'
            }}
        > 
        <div style={{fontWeight: '500', fontFamily: 'Segoe UI', marginBottom: '2.25rem',  marginLeft: '-1.25rem', fontSize: '20px'}}>
            {caseId}
        </div>
        </Toolbar>
    <div className="messages-container">

        {messages.map((message, index) => (
          <div
              key={index}
              className={`message-group ${message.role === 'ai' ? 'bg-gray-50 text-gray-800' : 'bg-white text-gray-800'}`}
              style={{ overflowWrap: 'anywhere', position: 'relative' }}
              
              onMouseEnter={() => setShowCopyIcon(index)}
              onMouseLeave={() => setShowCopyIcon(null)}
          >
            {message.role === 'ai' ? (
              <Tooltip title="Copy message" placement="top">
                  <IconButton
                      style={{ opacity: showCopyIcon === index ? 1 : .3, transition: 'opacity 0.3s ease-in-out'}}
                      onClick={() => handleCopyMessage(message.content)}
                      size="small"
                      sx={{ marginRight: '0.5rem', position: 'absolute', right: '5%', top: '10%' }}
                  >
                      <ContentCopyIcon  fontSize="small" />
                  </IconButton>
              </Tooltip>

          ) : (null)}


                <div className="message-wrapper">
                    <div className="avatar-section">
                      {message.role === 'ai' &&
                        <Chip label={message.avatar} className="c-name-chip" sx={{fontSize: "16px", maxWidth: "200px", color: '#fff', backgroundColor:'#1976D2'}} />
                      }
                        {message.role === 'ai' ? (
                            <Avatar
                            sx={{
                                width: 70,
                                height: 70,
                                background: "#f1f1f1",
                                color: "#f1f1f1",
                                boxShadow:'0px 0px 10px rgba(0, 0, 0, 0.5)'}}
                            > 
                                
                                    <img 
                                        src= {`/justpics/${message.avatar}.png`}
                                        alt= {`${message.avatar}`}
                                        style= {getImageStyle(message.avatar)} />
                            
                            </Avatar >

                        ) : (
                            <Avatar sx={{width:70, height: 70,  boxShadow:'0px 0px 10px rgba(0, 0, 0, 0.5)'}}>
                                <PersonIcon sx={{width: 50, height: 50}}/>
                            </Avatar>
                            
                        )}
                    </div>
                    <div 
                        className="message-content"                                
                    >
                        {nl2br(message.content)}
                        
                        
                    </div>
                </div>
            </div>
        ))
            }

                            <div ref={messagesEndRef} />
    </div>
        
    </div>
    <AppBar position="fixed" style={{ top: 'auto', bottom: 0 , zIndex: 2000}}>
      <Toolbar>

        <Button variant="contained" onClick={saveCurrentTranscript} color="primary" sx={{position: 'absolute', right: '16px', bottom: '80px'}}>
  Save & Exit
</Button>
      </Toolbar>
    </AppBar>
    </div>
    </div>
    );
}

export default TranscriptPage;