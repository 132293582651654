import OpenAI from 'openai';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useState } from 'react';



export const ArgumentCoach = ({isProcessing, setIsProcessing, caseId, party, allMessages}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [aiResponse, setAiResponse] = useState('');
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const requestAIHelp = async () => {
        const openai = new OpenAI({apiKey: process.env.REACT_APP_OPENAI_API_KEY, dangerouslyAllowBrowser: true});

        setIsProcessing(true);

        const prompt = `I am participating in a mock Supreme Court argument. The case is ${caseId}. I am the ${party}'s attorney. The transcript of the argument so far is ${JSON.stringify(allMessages.slice(1))}. I am struggling to formulate my next response. Give me some tips on how I might respond.`;

        try {
        const completion = await openai.chat.completions.create({
            model: "gpt-4",
            messages: [
            {"role": "system", "content": "You are a helpful assistant."},
            {"role": "user", "content": prompt}
            ],
        });

        setAiResponse(completion.choices[0].message.content.trim());
        
        } catch (error) {
        console.error('Error while talking to AI:', error);
        } finally {
        setIsProcessing(false); // Processing done
        }
    };

    const handleHelpClick = () => {
        requestAIHelp(); // Request help from AI (is this necessary? good practice?)
    };

    const handleClose = () => {
        if (!isProcessing) {
        setAnchorEl(null); // Close popover
        setAiResponse('');
        }
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget); // Open popover
    };


  return (
    <div>
        <Tooltip title="Get Help from an AI Argument Coach" placement="top">
            <Fab className='joyride-fab' color='primary' sx={{position: 'absolute', left: 16, bottom: 80}}
            onClick={(event) => {
            handleClick(event);
            handleHelpClick();
            }}>
                <TipsAndUpdatesIcon />
            </Fab>
        </Tooltip>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
            }}
            transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
            }}
            sx={{
            zIndex: 5000,
            '.MuiPaper-root': { 
                width: '300px',  // Chat widget width
                height: '400px', // Chat widget height
                overflow: 'hidden',
            }
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, borderBottom: 1, borderColor: 'divider' }}>
                
            <Typography variant="h6">Argument Coach</Typography>

            </Box>
            {/* Replace the following with your chat messages */}
            <Box sx={{ p: 2, height: 'calc(100% - 112px)', overflowY: 'auto' }}>
            {/* Your chat messages here */}
            <Typography sx={{ p: 2 }}>
                <div style={{ whiteSpace: 'pre-wrap' }}>
                {isProcessing ? "Thinking..." : aiResponse}
                </div>
                </Typography>
            </Box>

        </Popover>
    </div>
    );
};