export const handleButtonClick = (allMessages, removeHtmlTags, removeJusticeName, caseId, party, navigate) => {
    const renamedTranscriptData = allMessages.map(item => {
        const content = removeHtmlTags(item.message);
        const cleanedContent = removeJusticeName(content);
        return {
            ...item,
            content: cleanedContent,
        };
    });
  
    // Remove the 'message' field if you no longer need it
    renamedTranscriptData.forEach(item => {
        delete item.message;
        delete item.audio;
    });
  
    navigate('/transcript', { state: { messages: renamedTranscriptData, case: caseId, role: party } });
  };