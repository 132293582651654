import TopBar from "./TopBar";
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Button from "@mui/material/Button";
import { useState } from "react";
import { fetchData } from './api';
import { useNavigate, useLocation } from 'react-router-dom';


function NewCase() {
    const navigate = useNavigate();
    const location = useLocation();
    // Extract the state passed via navigate (if any)
    const initialState = location.state || {};

    // Initialize state either with values passed via navigate or with defaults
    const [caseName, setCaseName] = useState(initialState.caseName || '');
    const [role, setRole] = useState(initialState.role || '');
    const [caseFacts, setCaseFacts] = useState(initialState.caseFacts || '');
    const [questionPresented, setQuestionPresented] = useState(initialState.questionPresented || '');
    const [sumUserArgument, setSumUserArgument] = useState(initialState.sumUserArgument || '');
    const [sumOppArgument, setSumOppArgument] = useState(initialState.sumOppArgument || '');

    console.log("Location state:", location);
    console.log("Case Name:", caseName);
    const handleSubmit = async (e) => {
        e.preventDefault();
        fetchData('/api/addNewCase', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ caseName, role, caseFacts, questionPresented, sumUserArgument, sumOppArgument }),
            credentials: 'include',
        })
        .then(response => response.json())
        .then(data => {
          if(data.message === 'Case added!') {
            navigate("/my-cases");  // Refetch transcripts after saving
          }
        });
      };
    return (
        <div style={{display: 'flex', flexDirection: 'row'}} >
        <TopBar />    
        <div style={{backgroundColor: '#F7F7F7',  display: 'flex', flexDirection: 'column', flexGrow: 1, marginTop: '64px', padding: '64px'}}>
        <div style={{fontWeight: '500', fontFamily: 'Segoe UI', marginBottom: '2.25rem', marginTop: '-1.5rem', marginLeft: '-1.25rem', fontSize: '20px'}}>New Case</div>

            <div style={{display: 'flex', borderTop: '1px solid lightgrey'}}>
                <div style={{width: '40%'}}> 
                <div style={{fontWeight: '500', fontFamily: 'Segoe UI', marginBottom: '.25rem', marginTop: '.5rem', fontSize: '18px'}}>Case Name</div>
                </div>
                <TextField
                    value={caseName}
                    onChange={(e) => setCaseName(e.target.value)}
                    id="outlined-basic" 
                    label="Doe v. Smith"  
                    variant="outlined" 
                    style={{ width: '60%', margin: '2rem', backgroundColor: '#fff', marginLeft: '2rem'}} />
            </div>
        
        <div style={{display: 'flex', borderTop: '1px solid lightgrey'}}>
                <div style={{width: '40%'}}> 
                <div style={{fontWeight: '500', fontFamily: 'Segoe UI', marginBottom: '.25rem', marginTop: '.5rem', fontSize: '18px'}}>Party</div>
                </div>
            <FormControl> 
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={role}
                    name="radio-buttons-group"
                    row
                    onChange={(e) => setRole(e.target.value)}
                    style={{margin: '2rem'}}
                            >
                    <FormControlLabel value="appellant" control={<Radio />} label="Appellant/Petitioner" />
                    <FormControlLabel value="appellee" control={<Radio />} label="Appellee/Respondent" />
                </RadioGroup>        
                </FormControl>    
      </div>

            
            <div style={{display: 'flex', borderTop: '1px solid lightgrey'}}>
                <div style={{width: '40%'}}> 
                <div style={{fontWeight: '500', fontFamily: 'Segoe UI', marginBottom: '.25rem', marginTop: '.5rem', fontSize: '18px'}}>Case Facts</div>
                </div>
                <TextField 
                    multiline minRows={3} id="outlined-basic"
                    label="Case Facts"  variant="outlined"
                    value={caseFacts}
                    onChange={(e) => setCaseFacts(e.target.value)} 
                    style={{ width: '60%', margin: '2rem', backgroundColor: '#fff', marginLeft: '2rem'}} />
            </div>


            <div style={{display: 'flex', borderTop: '1px solid lightgrey', justifyContent: 'right'}}>
            <div style={{width: '40%'}}> 
            <div style={{fontWeight: '500', fontFamily: 'Segoe UI', marginBottom: '.25rem', marginTop: '.5rem', fontSize: '18px'}}>Question Presented</div>

            </div>
                <TextField 
                    multiline minRows={3} id="outlined-basic" 
                    label="Question Presented"  variant="outlined" 
                    value={questionPresented}
                    onChange={(e) => setQuestionPresented(e.target.value)}
                    style={{ width: '60%',  margin: '2rem', backgroundColor: '#fff', marginLeft: '2rem'}} />
                </div>


            <div style={{display: 'flex', borderTop: '1px solid lightgrey', justifyContent: 'right'}}>
            <div style={{width: '40%'}}> 
            <div style={{fontWeight: '500', fontFamily: 'Segoe UI', marginBottom: '.25rem', marginTop: '.5rem', fontSize: '18px'}}>Your Argument</div>
            </div>
                <TextField 
                    multiline minRows={3} id="outlined-basic" 
                    label="Summary of Your Argument"  
                    variant="outlined" 
                    value={sumUserArgument}
                    onChange={(e) => setSumUserArgument(e.target.value)}
                    style={{ width: '60%',  margin: '2rem', backgroundColor: '#fff', marginLeft: '2rem'}} />
                </div>


                <div style={{display: 'flex', borderTop: '1px solid lightgrey', justifyContent: 'right'}}>
                <div style={{width: '40%'}}>
                <div style={{fontWeight: '500', fontFamily: 'Segoe UI', marginBottom: '.25rem', marginTop: '.5rem', fontSize: '18px'}}>Their Argument</div>
                     </div>
                <TextField 
                    multiline minRows={3} id="outlined-basic"
                    label="Summary of Their Argument"  variant="outlined" 
                    value={sumOppArgument}
                    onChange={(e) => setSumOppArgument(e.target.value)}
                    style={{ width: '60%',  margin: '2rem', backgroundColor: '#fff', marginLeft: '2rem'}} />
</div>
    <div style={{display: 'flex', justifyContent: 'right', alignItems: 'bottom'}}>
                <Button 
                sx={{ width: '25%', mt: 7, mr: '2rem' }} 
                type="submit" 
                onClick={handleSubmit}
                variant="contained">
                    Add Case        
                </Button>
                </div>
    </div>

    </div>
    );
}

export default NewCase;