import React, { useState } from 'react';
import { Modal, Box, TextField, Button } from '@mui/material';

function AddCourseModal({ open, onClose, onAddCourse }) {
    const [courseData, setCourseData] = useState({ name: '', description: '' });

    const handleChange = (e) => {
        setCourseData({ ...courseData, [e.target.name]: e.target.value });
    };

    const handleSubmit = () => {
        fetch('https://api.arguendoai.com/api/courses', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(courseData)
        })
        .then(response => response.json())
        .then(data => {
            onAddCourse(data); // Update the courses list in parent component
        })
        .catch(error => console.error('Error adding course:', error));
    
        setCourseData({ name: '', description: '' });
    };
    

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={style}> {/* Style this box as needed */}
                <TextField name="name" label="Course Name" value={courseData.name} onChange={handleChange} margin='normal'/>
                <TextField name="description" label="Description" value={courseData.description} onChange={handleChange} margin='normal' />
                <Button type="submit" variant="contained" color="primary" fullWidth onClick={handleSubmit}>Add Course</Button>
            </Box>
        </Modal>
    );
}

export default AddCourseModal;
