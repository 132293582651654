import React, { useState, useEffect } from 'react';
import CourseCard from './CourseCard'; // A component to display each course
import { Button, Grid } from '@mui/material';
import AddCourseModal from './AddCourseModal'; // The modal for adding a new course
import TopBar from './TopBar';

function CoursesPage() {
    const [courses, setCourses] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        fetch('https://api.arguendoai.com/api/courses', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            }
            )
            .then(response => response.json())
            .then(data => 
                console.log(data) ||
                setCourses(data))
            .catch(error => console.error('Error fetching courses:', error));
    }, []);
    

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleAddCourse = (newCourse) => {
        setCourses(prevCourses => [...prevCourses, newCourse]);
        handleCloseModal();
    };
    

    
    
    return (
        <div>
            <TopBar />
            <Button variant="contained" onClick={handleOpenModal} sx={{ position:"absolute", right: "15%", top: "10%" }}>+ Add Course</Button>
            <Grid container spacing={0} sx={{justifyContent: "right"}} justifyContent= "right"> 
            {courses.map(course => (
                <Grid container key={course.id} xs={12} sm={6} md={4} justifyContent= "left" spacing={2}>
                <CourseCard key={course.id} course={course} />
                </Grid>
            ))}
            
            </Grid>
            <AddCourseModal open={isModalOpen} onClose={handleCloseModal} onAddCourse={handleAddCourse} />
        </div>
    );
}

export default CoursesPage;
