// RepeatButton.js
import React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import RepeatIcon from '@mui/icons-material/Repeat';
import { replayAudio } from './audioUtils';


const RepeatButton = ({ setIsSpeaking, className }) => {
  return (
    <Tooltip title="Repeat last question">
      <IconButton 
        className={className}
        color="primary" 
        onClick={() => replayAudio(setIsSpeaking)} // Use replayAudio here
        sx={{
          position: 'fixed',
          left: 20,
          top: 20,
          width: '3em', // Explicit width
          height: '3em', // Explicit height
          '& .MuiSvgIcon-root': {
            fontSize: '1.5rem', // Increase the font size of the icon
          }
        }}
      >
        <RepeatIcon />
      </IconButton>
    </Tooltip>
  );
};

export default RepeatButton;
