import { fetchData } from './api';

export const sendAudioToServer = async (audioBlob) => {
    const formData = new FormData();
    formData.append('file', audioBlob, 'audio.wav');

    try {
        const response = await fetchData('/api/upload-audio', {
            method: 'POST',
            credentials: 'include',
            body: formData,
        });
        if (!response.ok) {
          throw new Error(`Server responded with status ${response.status}`);
      }
    
    } catch (error) {
        console.error('Error uploading audio:', error);
    }
};