import React from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';

function CourseHome() {
    let { id } = useParams();
    let location = useLocation();
    let course = location.state.course;
    const courseLink = `/courses/${id}/`; 
    const assignmentsLink = `/courses/${id}/assignments`;
    const studentsLink = `/courses/${id}/students`;

    // Function to return the name of the current page based on the URL
    const getCurrentPage = () => {
        const pathSegments = location.pathname.split('/').filter(Boolean);
        // Assuming the last segment of the URL is the page name
        const pageName = pathSegments[pathSegments.length - 1];
        
        return pageName.charAt(0).toUpperCase() + pageName.slice(1); // Capitalize the first letter
    };

    // Determine what to display in the heading
    const displayHeading = () => {
        const currentPage = getCurrentPage();
        console.log(currentPage);

        if (currentPage === 'Assignments') {
            return (
            <> 
            <Link to={"/courses"} className='course-crumbs'>Courses</Link> / <Link to={courseLink} state={{ course: course }} className='course-crumbs'>{course.name}</Link> / <Link state={{ course: course }} className='course-crumbs' to={assignmentsLink}>Assignments</Link>
            </>
            );

        }
        else if (currentPage === 'Students') {
            return (
                <> 
                <Link to={"/courses"} className='course-crumbs'>Courses</Link> / <Link to={courseLink} state={{ course: course }} className='course-crumbs'>{course.name}</Link> / <Link state={{ course: course }} className='course-crumbs' to={studentsLink}>Students</Link>
                </>
                );
                    }
        else {
            return (
                <> 
                <Link to={"/courses"} className='course-crumbs'>Courses</Link> / <Link to={courseLink} state={{ course: course }} className='course-crumbs'>{course.name}</Link>
                </>
                );        }
    };    return (
        <div >
            <div> 
            <div className="course-header">            
                <h3 >{displayHeading()}</h3>
            </div>

            <div
                style={{
                    boxSizing: 'border-box',
                    width: '192px',
                    padding: '24px 12px 12px 24px',
                    height: '100vh',
                    position: "absolute",
                }}
            >
                <ul className="course-menu-list">
                    <li className="course-menu-list-item">
                        <Link                         
                        to={`/courses/${id}/`} 
                        state={{ course: course }}

                        className="course-menu-link"
                    >Home</Link>
                    </li>
                    <li className="course-menu-list-item">
                    <Link 
                        to={`/courses/${id}/assignments`} 
                        state={{ course: course }}
                        className="course-menu-link"
                    >
                        Assignments
                    </Link>
                    </li>
                    <li className="course-menu-list-item">
                    <Link 
                        to={`/courses/${id}/students`} 
                        state={{ course: course }}
                        className="course-menu-link"
                    >
                        Students
                    </Link>                    </li>
                </ul>
            </div>
            </div>
        </div>
    );
}

export default CourseHome;