import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useAuth } from './AuthContext';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import { useNavigate } from 'react-router-dom';

export default function HomeTopBar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{zIndex: 1200}}>
      <AppBar position="fixed" sx={{backgroundColor: '#ffffff' }}>

        <Toolbar>
        <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'center', position: 'absolute', left: '74px'}}>
        <ButtonBase onClick={() => navigate('/dashboard')} className='my-start-button'> 
            <Typography variant="h5" style={{ 
              fontFamily: 'Roboto Condensed, sans-serif', 
              fontStyle: 'italic', 
              color: '#1976d2' 
            }}>
Arguendo            </Typography>
            </ButtonBase>
          </div>
          {/* ... potentially other AppBar items ... */}
          <div style={{ flexGrow: 1 }} />
          <div style={{padding: "0px 15px 0px 0px"}} >
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle style= {{fontSize: 30, color: '333' }}/>
            </IconButton>
            <Menu
              
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={open}
              onClose={handleClose}
            >
              {/* Here you can add other items if needed */}
              <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}