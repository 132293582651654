import { fetchData } from './api';

export const handleGavelClick = async (caseDetails, setIsLoading, setTranscriptData, appendMessage, setRun, formatMessageData, handlePreloadedMessages, setMessages) => {
  setIsLoading(true);
  fetchData('/api/init-voice', {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(caseDetails)
  })
  .then(res => res.json())
  .then(data => {
    const messages = data.messages;
    const formattedMessages = formatMessageData(messages); // Ensure formatMessageData is defined or imported
    setTranscriptData(formattedMessages);
    handlePreloadedMessages(formattedMessages, setMessages);
    appendMessage(formattedMessages);
    setIsLoading(false);
  })
  .then(() => {
    const hasTakenTour = localStorage.getItem('hasTakenTour');
    if (!hasTakenTour) {
      setRun(true);
      localStorage.setItem('hasTakenTour', 'true');
    }
  })
  .catch(error => {
    console.error('Fetch Error: ', error);
  });
};
