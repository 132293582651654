import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext'; 
import { fetchData } from './api';


function AuthForm({ mode }) {
  const [formData, setFormData] = useState({
    username: '',
    password: ''
  });

  const navigate = useNavigate();
  const { setIsAuthenticated } = useAuth(); 

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const endpoint = mode === 'register' ? '/api/register' : '/api/login';
      
      const response = await fetchData(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include', // Needed to include the cookie
        body: JSON.stringify(formData)
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const responseData = await response.json();
      console.log(responseData);
  
      if (mode === 'login' && responseData.message === "Login successful") {
        // Save user's session or token as needed, and redirect or change the app state
        setIsAuthenticated(true);
        navigate('/dashboard');
      }
    } catch (error) {
      console.error("Error during auth request:", error);
    }
  };
  
  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3} direction="column" alignItems="center">
        <Grid item>
          <Typography variant="h5">
            {mode === 'register' ? 'Register' : 'Login'}
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            label="Username"
            variant="outlined"
            name="username"
            value={formData.username}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item>
          <TextField
            label="Password"
            variant="outlined"
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" type="submit">
            {mode === 'register' ? 'Register' : 'Login'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default AuthForm;
