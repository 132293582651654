import {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import TopBar from './TopBar';
import { useTranscripts } from './TranscriptContext';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Delete from '@mui/icons-material/Delete';
import { fetchData } from './api';
import TableContainer from '@mui/material/TableContainer';
import TopProgressBar from './TopProgressBar';




function SavedTranscriptsList() {
  const { savedTranscripts, deleteTranscriptFromState } = useTranscripts();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    // Simulate progress before data is fully loaded
    const timer = setInterval(() => {
        setProgress((oldProgress) => {
            const newProgress = oldProgress + 10;
            if (newProgress === 100) {
                clearInterval(timer);
            }
            return newProgress;
        });
    }, 100);

    return () => {
        clearInterval(timer);
    };
}, []);

  const handleCasesLoaded = () => {
    setProgress(100);
    setLoading(false);
  };

  const handleRowClick = (id) => {
      navigate(`/saved-transcripts/${id}`);
  };



  const handleDeleteClick = async (event, id) => {
    event.stopPropagation();

    try {
        const response = await fetchData(`/api/deleteTranscript/${id}`, {
            method: 'DELETE',
            credentials: 'include',
        });

        const data = await response.json();
        if (response.status === 200) {
            deleteTranscriptFromState(id);  
            // Here, re-fetch the transcripts or remove it from state if you're using a local state
        } else {
            console.error("Error deleting transcript:", data.error);
        }
    } catch (error) {
        console.error("Network error:", error);
    }
};





  return (
      <div style={{display:'flex', }}>
        <TopBar />
        
        <div style={{backgroundColor: '#F7F7F7',  display: 'flex', height: '100vh', flexDirection: 'column', flexGrow: 1, marginTop: '64px', padding: '64px'}}>
            
            <div style={{fontWeight: '500', fontFamily: 'Segoe UI', marginBottom: '2.25rem', marginTop: '-1.5rem', marginLeft: '-1.25rem', fontSize: '20px'}}>Saved Transcripts</div>
<div className='case-library' >

            <Paper elevation={1} sx={{width: '90%'}}>

        <TableContainer >
              <Table>
                  <TableHead>
                      <TableRow>
                          <TableCell>#</TableCell>
                          <TableCell>Case</TableCell>
                          <TableCell>Role</TableCell>
                          <TableCell>Date Created</TableCell>
                          <TableCell>Delete</TableCell> {/* New cell for delete action */}
                      </TableRow>
                  </TableHead>
                  <TableBody>
  {savedTranscripts.length > 0 ? (
    savedTranscripts.map((transcript, index) => (
      <TableRow 
        key={index} 
        hover 
        onClick={() => handleRowClick(index + 1)}
        style={{ cursor: 'pointer' }}
      >
        <TableCell>{index + 1}</TableCell>
        <TableCell>{transcript.case_name}</TableCell>
        <TableCell style={{ textTransform: 'capitalize' }}>{transcript.role}</TableCell>
        <TableCell>{transcript.date_created}</TableCell>
        <TableCell>
          <IconButton onClick={(event) => handleDeleteClick(event, transcript.id)}>
            <Delete />
          </IconButton>
        </TableCell>
      </TableRow>
    ))
  ) : (
    <TableRow>
      <TableCell colSpan={5} align="center">
        <Typography variant="subtitle1" gutterBottom>
          No saved transcripts available.
        </Typography>
      </TableCell>
    </TableRow>
  )}
</TableBody>

              </Table>
            </TableContainer>
          </Paper>
      </div>
    </div>
  </div>
  );

}

export default SavedTranscriptsList;
