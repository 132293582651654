import React, { useState } from 'react';
import { Modal, Box, TextField, Button, Typography } from '@mui/material';

function AddStudentModal({ open, onClose, courseId }) {
    const [studentName, setStudentName] = useState('');
    const [studentEmail, setStudentEmail] = useState('');


    const handleSubmit = async (event) => {
        event.preventDefault();

        fetch(`https://api.arguendoai.com/api/courses/${courseId}/add_students`, {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ studentName: studentName, studentEmail: studentEmail })
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            onClose(); // Close the modal after adding
            // Optionally refresh the course details to show the new student
        })
        .catch(error => {
            console.error('Error:', error);
        });
    
        // Reset form fields
        setStudentName('');
        setStudentEmail('');

      
    };
    
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
    };

    return (
        <Modal open={open} onClose={onClose} aria-labelledby="add-student-modal-title">
            <Box sx={modalStyle}>
                <Typography id="add-student-modal-title" variant="h6" component="h2">
                    Add a New Student
                </Typography>
                <TextField
                    label="Student Name"
                    value={studentName}
                    onChange={e => setStudentName(e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Student Email"
                    value={studentEmail}
                    onChange={e => setStudentEmail(e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <Button variant="contained" onClick={handleSubmit} sx={{ mt: 2 }}>
                    Add Student
                </Button>
            </Box>
        </Modal>
    );
}

export default AddStudentModal;
