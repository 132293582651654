import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';

function SearchResultsPage() {
  const { query } = useParams();
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const response = await fetch(`http://localhost:5000/api/search?query=${query}`);
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        const data = await response.json();
        setSearchResults(data); // Adjusted this line
      } catch (error) {
        console.error("Error fetching the search results:", error);
      }
    };

    fetchResults();
  }, [query]);
  return (
    <div className="search-results-container">
      <h1>Search Results for "{query}"</h1>
      {searchResults.map(result => (
        <Link to={`/case/${result._id}`} key={result._id} className="search-result-link">
          <div className="search-result">
            <h3>{result._source.name}</h3>
            <p>{result._source.description}</p>
          </div>
        </Link>
      ))}
    </div>
  );
}

export default SearchResultsPage;
