import React, {useState, useEffect} from "react";
import { useLocation, useParams } from "react-router-dom";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Button } from "@mui/material";
import AddAssignmentModal from "./AddAssignmentModal";
import CourseHome from "./CourseHome";


function CourseAssignments() {
    let location = useLocation();
    let course = location.state.course;
    let { id } = useParams(); // Get the course ID from the URL

    const [assignments, setAssignments] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);


    const fetchAssignments = () => {
        fetch(`https://api.arguendoai.com/api/courses/${id}/assignments`)
            .then(response => response.json())
            .then(data => setAssignments(data))
            .then(console.log(assignments))
            .catch(error => console.error('Error:', error));
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    useEffect(() => {
        fetchAssignments();
    }, [id]);

    const handleAssignmentAdded = () => {
        fetchAssignments();
        setIsModalOpen(false);
    };

  return (
    <div >
        <CourseHome />
        <div style={{marginLeft: "192px", marginRight: "24px"}}>
   
      <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Case</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {assignments && assignments.length > 0 ? assignments.map((assignment) => (
                            <TableRow key={assignment.id}>
                                <TableCell>{assignment.name}</TableCell>
                                <TableCell>{assignment.assigned_case}</TableCell>
                            </TableRow>
                        )) : (
                        <TableRow>
                            <TableCell>No Assignments</TableCell>
                            </TableRow>
                        )
                        }
                            

                    </TableBody>
                </Table>
            </TableContainer>
            <Button onClick={handleOpenModal}>Add Assignment</Button>
            <AddAssignmentModal open={isModalOpen} onClose={handleAssignmentAdded} courseId={id}  />
    </div>
    </div>
    );
}

export default CourseAssignments;