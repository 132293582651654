import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useAuth } from './AuthContext';
import MenuIcon from '@mui/icons-material/Menu';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';
import Home from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ListItemIcon from '@mui/material/ListItemIcon';
import { styled, useTheme } from '@mui/material/styles';
import CasesSharpIcon from '@mui/icons-material/CasesSharp';
import MedicalServicesSharpIcon from '@mui/icons-material/MedicalServicesSharp';
import GroupsIcon from '@mui/icons-material/Groups';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  zIndex: 100,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  zIndex: 100,
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'openDrawer' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    zIndex: 100,
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function TopBar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { logout } = useAuth();
  const [openDrawer, setOpenDrawer] = React.useState(true);
  
  

  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };




  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };
  const navigate = useNavigate();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const theme = useTheme();

  return (
    <div >
      <AppBar position="fixed" sx={{ backgroundColor: '#ffffff',  boxShadow: 'none', borderBottom: '1px solid rgba(0,0,0,.12)' }}>
        <Toolbar>
          {/* Menu Icon */}
          <div style={{padding: "0px 0px 0px 10px"}}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            sx={{ mr: 2 }}
          >
            <MenuIcon style={{ color: '#333' }} />
          </IconButton>
          </div>
          <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'center', position: 'absolute', left: '74px' }}>
          <ButtonBase onClick={() => navigate('/dashboard')} className='my-start-button'> 
            <Typography variant="h5" style={{ 
              fontFamily: 'Roboto Condensed, sans-serif', 
              fontStyle: 'italic', 
              color: '#1976d2' 
            }}>
Arguendo            </Typography>
            </ButtonBase>
          </div>
          {/* Other AppBar items can go here */}
          <div style={{ flexGrow: 1 }} />

          {/* Home Icon */}
          <div style={{marginRight: '30px'}}>
          <IconButton
              edge="end"
              aria-label="home"
              aria-controls="menu-appbar"
              onClick={() => navigate('/dashboard')}
              color="inherit"
            >
              <Home style={{ fontSize: 30, color: '#333' }} />
            </IconButton>
          </div>
          {/* Account Icon */}
          <div style={{ padding: "0px 15px 0px 0px" }}>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle style={{ fontSize: 30, color: '#333' }} />
            </IconButton>
  
            {/* Menu */}
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={open}
              onClose={handleClose}
              sx={{ zIndex: 1400 }}
            >
              {/* Menu items */}
              <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      
      {/* Drawer */}
      <Drawer
        anchor="left"
        variant="permanent"
        open={openDrawer}

      >
      <List sx={{ paddingTop: '100px'}}>
      <ListItemButton component={Link} to={`/dashboard`}>
          <ListItemIcon>
          <Home/>
          </ListItemIcon>
          <ListItemText primary="Home" primaryTypographyProps={{ color: '#333', fontWeight: 900}} />
        </ListItemButton>

        <ListItemButton component={Link} to={`/case-library`}>
          <ListItemIcon>
          <AccountBalanceIcon />
          </ListItemIcon>
          <ListItemText primary="Case Library" primaryTypographyProps={{ color: '#333', fontWeight: 900}} />
        </ListItemButton>

        <ListItemButton component={Link} to={`/my-cases`}>
          <ListItemIcon>
          <CasesSharpIcon />
          </ListItemIcon>
          <ListItemText primary="My Cases" primaryTypographyProps={{ color: '#333', fontWeight: 900}} />
        </ListItemButton>

        <ListItemButton component={Link} to={`/new-case`}>
          <ListItemIcon>
          <MedicalServicesSharpIcon />
          </ListItemIcon>
          <ListItemText primary="New Case" primaryTypographyProps={{ color: '#333', fontWeight: 900}} />
        </ListItemButton>

        <ListItemButton component={Link} to={`/saved-transcripts`}>
          <ListItemIcon>
          <QuestionAnswerIcon />
          </ListItemIcon>
            <ListItemText primary="Transcripts" primaryTypographyProps={{color: '#333', fontWeight: 900}} />
        </ListItemButton>

        

        {/* Add more ListItemButton components for additional sidebar items */}
      </List>
      </Drawer>
    </div>
  );
}  