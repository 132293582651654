import React, { useState, useRef, useEffect } from 'react';
import './ConversationUI.css';
import PersonIcon from '@mui/icons-material/Person';
import SendIcon from '@mui/icons-material/Send';
import nl2br from 'react-nl2br';
import  Avatar  from '@mui/material/Avatar';
import GavelModal from './Gavel';
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Chip from '@mui/material/Chip';
import  AppBar  from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchData } from './api';
import Fab from '@mui/material/Fab';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import OpenAI from 'openai';
import Typography from '@mui/material/Typography';




const ConversationUI_DC = (props) => {
    const [messages, setMessages] = useState([]);
    const [userInput, setUserInput] = useState('');
    const textareaRef = useRef(null);
    const messagesEndRef = useRef(null);
    const [showCopyIcon, setShowCopyIcon] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isAIResponding, setIsAIResponding] = useState(false);
    let { caseId, party, mode } = useParams();
    const navigate = useNavigate();

        

    const openai = new OpenAI({apiKey: process.env.REACT_APP_OPENAI_API_KEY, dangerouslyAllowBrowser: true});
    const [aiResponse, setAiResponse] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);


        

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
      setAiResponse('');
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const requestAIHelp = async () => {
      setIsProcessing(true); // Indicate that processing is happening
  
      // Construct the prompt with the current state variables
      const prompt = `I am participating in a mock DC Circuit argument. The case is ${caseId}. I am the ${party}'s attorney. The transcript of the argument so far is ${JSON.stringify(messages.slice(1))}. I am struggling to formulate my next response. Give me some tips on how I might respond.`;
  
      try {
        const completion = await openai.chat.completions.create({
          model: "gpt-4",
          messages: [
            {"role": "system", "content": "You are a helpful assistant."},
            {"role": "user", "content": prompt}
          ],
        });
  
        // Note: Depending on the API response structure, you may need to adjust the following line
        setAiResponse(completion.choices[0].message.content.trim());
  
        // Update your chat history or message display with the AI's response
        
      } catch (error) {
        console.error('Error while talking to AI:', error);
      } finally {
        console.log(aiResponse);
        setIsProcessing(false); // Processing done
      }
    };
  
    const handleHelpClick = () => {
      requestAIHelp();
    };

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);
    
    const handleCopyMessage = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            // Handle success, maybe display a small toast or tooltip to the user.
            console.log("Text copied successfully!");
        }).catch((err) => {
            console.error("Error copying text: ", err);
        });
    };
    

    const formatJusticeName = (message) => {
        return message.replace(/JUDGE ([A-Z]+):/, (match, p1) => {
          return ``;
        });
      }
      
      const removeJusticeName = (message) => {
        // Remove the justice name from the message, e.g. "Justice Roberts: " -> ""
        //Note that Justice is capitalized in the regex, but not in the message
        return message.replace(/Judge ([A-Za-z]+):/, (match, p1) => {
          return ``;
        });
      }
      
      const formatMessageData = (messages) => {
          messages = JSON.parse(messages);
          console.log(messages);
          console.log(Array.isArray(messages));
      
          return messages.map(message => {
            if (message.type === "constructor") {
              switch (message.id[3]) {
                case "HumanMessage":
                  return {
                    type: "human",
                    content: message.kwargs.content
                  };
                case "AIMessage":
                  // Here, we apply the formatJusticeName function
                  const formattedContent = formatJusticeName(message.kwargs.content);
                  console.log(message.kwargs.additional_kwargs?.justice);
                  const justiceAvatar = message.kwargs.additional_kwargs?.justice || "John Roberts";
                  return {
                    type: "ai",
                    role: "ai",
                    sender: "ai",
                    content: formattedContent,
                    avatar: justiceAvatar,
                    justice: justiceAvatar
                  };
                default:
                  return null; // or some default behavior for unrecognized types
              }
            }
            return null; // or some default behavior for unrecognized types
          }).filter(message => message !== null); // filter out null entries
      }
      
    
    const handleSendMessage = async () => {
        if (userInput.trim() !== '') {
            setMessages((prevMessages) => [...prevMessages, { role: 'human', sender: 'user', content: userInput }]);
            // Send the user message to the Flask backend
setUserInput('');
            setIsAIResponding(true);
                        try {
                let response = await fetchData('/api/text-input', {
                    method: 'POST', // Set the method to POST
                    credentials: 'include', // Include the session cookie
                    headers: {
                        'Content-Type': 'application/json' // Indicate that we're sending JSON data
                    },
                    body: JSON.stringify({ text: userInput }) // Convert the payload to a JSON string
                });
                
                if (!response.ok) { 
                    throw new Error('Network response was not ok'); 
                }
    
                let responseData = await response.json(); // Parse the response as JSON
                let aiResponse = responseData.response;
                let aiAvatar = responseData.avatar;

                // Format the AI response
                aiResponse = removeJusticeName(aiResponse);
                // Add the AI response to messages
                setMessages((prevMessages) => [...prevMessages, { role: 'ai', sender: 'ai', content: aiResponse, avatar: aiAvatar }]);
setIsAIResponding(false);
                            } catch (error) {
                console.error("There was an error sending the message:", error);
setIsAIResponding(false);
                            }
            
        }
    };
    



    const handleButtonClick = () => {
      navigate('/transcript', { state: { messages: messages, case: caseId, role: party } });
  };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault(); // Prevent the default behavior of Enter key (new line)
            handleSendMessage(); // Call your send message function
        }
    };

    const handleGavelClick = async () => {
      setIsLoading(true);
      fetchData('/api/init-voice', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ caseId: caseId, role: party })
      })    
      .then(res => {
          return res.json();
        })
        .then(data => {
            const messages = data.messages;
          const formattedMessages = formatMessageData(messages);
          setMessages(formattedMessages);
            setIsLoading(false);
                    })
        .catch(error => {
          console.log('Fetch Error: ', error);
        });
    };
    // AI Skeleton
const AISkeleton = () => {
    return (
      <div className="message-group bg-gray-50 text-gray-800">
        <div className="message-wrapper">
          <div className="avatar-section">
            <div className="skeleton-avatar bg-gray-300"></div>
          </div>
          <div className="skeleton-message-content bg-gray-300"></div>
        </div>
      </div>
    );
  };
  
  // Human Skeleton
  const HumanSkeleton = () => {
    return (
      <div className="message-group bg-white text-gray-800">
        <div className="message-wrapper">
          <div className="avatar-section">
            <div className="skeleton-avatar bg-gray-300"></div>
          </div>
          <div className="skeleton-message-content bg-gray-300"></div>
        </div>
      </div>
    );
  };
  
  const handleTextareaChange = (e) => {
    setUserInput(e.target.value);
    const textarea = e.target;
    
    // Adjust height
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
};

  const getImageStyle = (avatarName) => {
    switch (avatarName) {
      case 'Judge Kavanaugh':
        return {
            transform: 'scale(.45)',
            position: 'relative',
            top: '15%'
            
        };
      case 'Justice Kagan':
        return {
          position: 'relative',
          transform: 'scale(.45)',
          left: '7%',
          top: '15%',
        };
        case 'Justice Alito':
            return {
              position: 'relative',
              transform: 'scale(.45)',
          
              top: '17%',
            };
        case 'Justice Gorsuch':
            return {
              position: 'relative',
              transform: 'scale(.43)',
              left: '2%',
              top: '15%',
            };
    
      // add more cases as needed
      default:
        return {
            position: 'relative',
            transform: 'scale(.70)'
         };
    }
  };
    






    return (
        <div className="conversation-container">
    
            <div className="messages-container">
                {isLoading ? (
                    <>
                        <AISkeleton />
                        <HumanSkeleton />
                        <AISkeleton />
                    </>
                    ) : (
                messages.map((message, index) => (
                  <div
                      key={index}
                      className={`message-group ${message.sender === 'ai' ? 'bg-gray-50 text-gray-800' : 'bg-white text-gray-800'}`}
                      style={{ overflowWrap: 'anywhere', position: 'relative' }}
                      
                      onMouseEnter={() => setShowCopyIcon(index)}
                      onMouseLeave={() => setShowCopyIcon(null)}
                  >
                    {message.sender === 'ai' ? (
                      <Tooltip title="Copy message" placement="top">
                          <IconButton
                              style={{ opacity: showCopyIcon === index ? 1 : .3, transition: 'opacity 0.3s ease-in-out'}}
                              onClick={() => handleCopyMessage(message.content)}
                              size="small"
                              sx={{ marginRight: '0.5rem', position: 'absolute', right: '5%', top: '10%' }}
                          >
                              <ContentCopyIcon  fontSize="small" />
                          </IconButton>
                      </Tooltip>

                  ) : (null)}


                        <div className="message-wrapper">
                            <div className="avatar-section">
                              {message.sender === 'ai' &&
                                <Chip label={message.avatar} className="c-name-chip" sx={{fontSize: "16px", maxWidth: "200px", color: '#fff', backgroundColor:'#1976D2'}} />
                              }

{message.sender === 'ai' ? (
                                    <Avatar
                                    sx={{
                                        width: 70,
                                        height: 70,
                                        background: "#f1f1f1",
                                        color: "#f1f1f1",
                                        boxShadow:'0px 0px 10px rgba(0, 0, 0, 0.5)'}}
                                    > 
                                        
                                            <img 
                                                src= {`/justpics/${message.avatar}.png`}
                                                alt= {`${message.avatar}`}
                                                style= {getImageStyle(message.avatar)} />
                                    
                                    </Avatar >

                                ) : (
                                    <Avatar sx={{width:70, height: 70,  boxShadow:'0px 0px 10px rgba(0, 0, 0, 0.5)'}}>
                                        <PersonIcon sx={{width: 50, height: 50}}/>
                                    </Avatar>
                                    
                                )}
                                
                            </div>
                            <div 
                                className="message-content"                                
                            >
                                {nl2br(message.content)}
                                
                                
                            </div>
                        </div>
                    </div>
                ))
                    )}
{isAIResponding && <AISkeleton />}
                                    <div ref={messagesEndRef} />
            </div>
    
            <div className="input-section">
                <div className="text-input-container">
                    <textarea
                        ref={textareaRef}
                        className="message-input"
                        value={userInput}
                        onChange={handleTextareaChange}
                        onKeyDown={(e) => {
                            handleKeyPress(e);
                            }
                        }
                        placeholder="Type your message..."
                    />
                    <button className="send-button" onClick={handleSendMessage} >
                        <SendIcon color="inherit" cursor="pointer" />
                    </button>
                </div>
            </div>
            <Tooltip title="Get help from an AI Argument Coach" placement="top">
            <Fab color='primary' sx={{position: 'absolute', left: 16, bottom: 16}}
  onClick={(event) => {
    handleClick(event);
    handleHelpClick();
  }}>
  <TipsAndUpdatesIcon />
</Fab>
</Tooltip>
<Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          zIndex: 5000,
          '.MuiPaper-root': { 
            width: '300px',  // Chat widget width
            height: '400px', // Chat widget height
            overflow: 'hidden',
          }
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, borderBottom: 1, borderColor: 'divider' }}>
          <Typography variant="h6">Argument Coach</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        {/* Replace the following with your chat messages */}
        <Box sx={{ p: 2, height: 'calc(100% - 112px)', overflowY: 'auto' }}>
          {/* Your chat messages here */}
          <Typography sx={{ p: 2 }}>
          <div style={{ whiteSpace: 'pre-wrap' }}>
  {isProcessing ? "Thinking..." : aiResponse}
</div>
  </Typography>
        </Box>

       
      </Popover>
            <AppBar position="fixed" style={{ top: 'auto', bottom: 0 , zIndex: 2000}}>
      <Toolbar>
        <Button variant="contained" onClick={handleButtonClick} color="error" sx={{position: 'absolute', right: '32px'}}>
  Exit
</Button>
      </Toolbar>
    </AppBar>
            <GavelModal onGavelClick={handleGavelClick} />
            </div>
            
    );
}

export default ConversationUI_DC;
    