import TopBar from "./TopBar";

function MyCourts() {
  return (
    <div style={{display: 'flex', flexDirection: 'row'}} >
    <TopBar />    
    <div style={{backgroundColor: '#F7F7F7', height: 'calc(100vh - 64px)', display: 'flex', flexDirection: 'column', flexGrow: 1, marginTop: '64px', padding: '64px'}}>
    <div style={{fontWeight: '500', fontFamily: 'Segoe UI', marginBottom: '2.25rem', marginTop: '-1.5rem', marginLeft: '-1.25rem', fontSize: '20px'}}>Courts</div>

    </div>
    </div>

  );
}

export default MyCourts;