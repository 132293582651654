import React, { useState } from 'react';
import AuthForm from './AuthForm';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

function AuthPage() {
  const [mode, setMode] = useState('login');

  const switchMode = () => {
    setMode((prevMode) => (prevMode === 'login' ? 'register' : 'login'));
  };

  return (
    <Container maxWidth="sm">
      <Box mt={5}>
        <AuthForm mode={mode} />
        <Button onClick={switchMode}>
          {mode === 'login' ? 'Switch to Register' : 'Switch to Login'}
        </Button>
      </Box>
    </Container>
  );
}

export default AuthPage;
