import { useAuth } from './AuthContext';
import { Navigate } from 'react-router-dom';

export default function ProtectedRoute({ children }) {
    let { isAuthenticated } = useAuth();
    
    if (!isAuthenticated) {
      return <Navigate to="/sign-in" replace={true} />;
    }
    
    return children;
  }
  
