let mediaRecorder;
let audioChunks = [];
let isRecorderInitialized = false;

// This function requests access to the microphone and initializes the MediaRecorder
export const initializeAudioCapture = async () => {
    try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        mediaRecorder = new MediaRecorder(stream);
        isRecorderInitialized = true;
        
        mediaRecorder.ondataavailable = event => {
            if (event.data.size > 0) {
                audioChunks.push(event.data);
            }
        };
    } catch (error) {
        console.error('Error initializing media recorder:', error);
    }
}

export const startAudioCapture = async () => {
    if (!isRecorderInitialized) {
        console.log('MediaRecorder has not been initialized. Initializing now...');
        await initializeAudioCapture();
    }
    
    // Check again if the MediaRecorder has been initialized after attempting to initialize.
    if (!isRecorderInitialized) {
        console.error('MediaRecorder could not be initialized.');
        return;
    }
    
    // Clear previous audio data
    audioChunks = [];

    // Start recording
    mediaRecorder.start();
    console.log('Recording started');
}

// ... rest of your stopAudioCapture function ...


export const stopAudioCapture = async () => {
    if (!mediaRecorder) {
        console.error('MediaRecorder is not initialized.');
        return;
    }

    return new Promise((resolve) => {
        mediaRecorder.onstop = () => {
            console.log("mediaRecorder has stopped");
            const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
            resolve(audioBlob);
        };
        
        mediaRecorder.stop();
    });
}




