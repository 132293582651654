import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { Link as RouterLink } from 'react-router-dom';
import { fetchData } from './api';


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://arguendoai.com/">
        Arguendo
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function NewSignIn() {
    const navigate = useNavigate();
    const { setIsAuthenticated } = useAuth();
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const payload = {
        email: data.get('email'),
        password: data.get('password'),
    };
    console.log(payload); // Debug line
    try {
        const response = await fetchData('/api/login', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json'
            },
            credentials: 'include', // Needed to include the cookie
            body: JSON.stringify(payload)
        });
    
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
    
        const responseData = await response.json();
        console.log(responseData);
    
        if (responseData.message === "Login successful") {
            // Save user's session or token as needed, and redirect or change the app state
            setIsAuthenticated(true);
            navigate('/dashboard');
        }
    } catch (error) {
        console.error("Error during auth request:", error);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
              <Box
                component="img"
                sx={{
                  m: 1, // Margin, adjust as needed
                  width: 1/2, // Width, adjust according to your logo's size
                  height: 1/2 // Height, adjust according to your logo's size
                }}
                src={`${process.env.PUBLIC_URL}/ArgoLogo.png`} // Path to your logo in the public folder
                alt="Your Logo"
              />
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link component={RouterLink} to="/sign-up" variant="body2">
                {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}