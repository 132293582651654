import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { startAudioCapture, stopAudioCapture, initializeAudioCapture } from './audioCaptureUtils';
import { playAudioChunk } from './audioUtils';
import RepeatButton from './RepeatButton';  
import LiveTranscript from './LiveTranscript';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import GavelModal from './Gavel';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import useResponsiveWidth from './ResponsiveWidth';
import Joyride from 'react-joyride';
import { removeHtmlTags, removeJusticeName, formatMessageData, boldJusticeName } from './StringUtils.js';
import steps from './TourSteps.js';
import { ArgumentCoach } from './ArgCoach.js'
import { GridContainer, CenteringWrapper, SpeakingAvatar, NotSpeakingAvatar } from './VoiceStyle.js';
import { sendAudioToServer } from './SendAudio.js';
import { handleGavelClick } from './GavelHandler.js';
import useFetchCourtData from './useFetchCourtData.js';
import useSocketConnection from './useSocketConnection.js';
import { handleButtonClick } from './ExitButtonHandler.js';
import io from 'socket.io-client';
import initiateTextToSpeech from './elevenSock.js';
import { handleUserMessage, bufferMessageChunk, handlePreloadedMessages } from './messageUtils.js';


const JusticeAvatars = (props) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [transcriptData, setTranscriptData] = useState(null);
  const [isTranscriptVisible, setIsTranscriptVisible] = useState(true);
  const [isSpeaking, setIsSpeaking] = useState('');
  const [justices, setJustices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [allMessages, setAllMessages] = useState([]);
  const responsiveWidth = useResponsiveWidth();
  const [run, setRun] = useState(false);
  const caseId = props.caseId;
  const party = props.party;
  const caseDetails = props.caseDetails;
  const heardByIdentifier = props.heardByIdentifier;
  const [listening, setListening] = useState(false);
  const [messages, setMessages] = useState([]);
  //const [speakingJustice, setSpeakingJustice] = useState('');


  useFetchCourtData(heardByIdentifier, setJustices, initializeAudioCapture);


  const appendMessage = (messages) => {
    // Check if messages is an array and if so spread each message, otherwise just append the message object
    setAllMessages(prevMessages => Array.isArray(messages) ? [...prevMessages, ...messages] : [...prevMessages, messages]);
  };

  /*useSocketConnection(setIsSpeaking, setTranscriptData, appendMessage, playAudioChunk, latestAudioInfo, boldJusticeName, setIsProcessing);*/
useEffect(() => {
    const socket = io.connect('https://api.arguendoai.com', { origins: '*:*', withCredentials: true });
    console.log('Socket connection established');

    const handleTranscriptionComplete = (data) => {
      console.log("Transcription received:", data.message);
      setTranscriptData(data);
      handleUserMessage(data, setMessages);
      appendMessage(data);
    };

    const handleCompleteMessage = (data) => {
      console.log("Transcription received:", data.message);
      const renamedMessage = {
        ...data,
        avatar: data.justice
      };
      appendMessage(renamedMessage);
    };


    const handleTextChunk = (data) => {
      if (data && data.justice) {
        setIsSpeaking(data.justice);  // Light up the avatar
      }
      
      //latestAudioInfo.justice = data.justice || '';
      //data.message = boldJusticeName(data);
      // Prepend `data.voice` to `data.message` with formatting
      if (data.justice && data.is_first_chunk) {
      data.new_chunk = `<strong>${data.justice}:</strong><br>${data.new_chunk}` || `<strong>${data.justice}:</strong><br>`;
      bufferMessageChunk(data, setMessages, messages);
      } else {
      bufferMessageChunk(data, setMessages, messages);}

      setTranscriptData(data);

      const renamedMessage = {
        ...data,
        avatar: data.justice
      };
      //appendMessage(renamedMessage);
      setIsProcessing(false);
      console.log("Text chunk received:", data);
    };





    const handleNewAudioChunk = (data) => {
      playAudioChunk(data, setIsSpeaking);
    };
    
    
  
    // Register event listeners
    socket.on('transcription_complete', handleTranscriptionComplete);
    socket.on('text_chunk', handleTextChunk);
    socket.on('new_audio_chunk', handleNewAudioChunk);
    socket.on('complete_message', handleCompleteMessage);





return () => {
      socket.off('transcription_complete', handleTranscriptionComplete);
      socket.off('text_chunk', handleTextChunk);
      socket.off('new_audio_chunk', handleNewAudioChunk);
      socket.off('complete_message', handleCompleteMessage);
      
  
socket.disconnect();
    };
  }, []);


  
  const onExitButtonClick = () => handleButtonClick(allMessages, removeHtmlTags, removeJusticeName, caseId, party, navigate);

  const onGavelClick = () => handleGavelClick(caseDetails, setIsLoading, setTranscriptData, appendMessage, setRun, formatMessageData, handlePreloadedMessages, setMessages);

  const handleMouseDown = async () => {
    setListening(true);
    console.log("Starting to listen...");
    startAudioCapture();
  }

  const handleMouseUp = async () => {
      setListening(false);

      const  audioBlob  = await stopAudioCapture(); 

      console.log("Sending audio blob to server...");
      setTimeout(() => {
        sendAudioToServer(audioBlob);
      }, 1);
      setIsProcessing(true);

  }; 

  const handleChange = () => {
    setIsTranscriptVisible((prev) => !prev);;
  };


  const containerStyle = {
    zIndex: 3000,
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    position: 'relative',
    overflow: 'auto',
    flex: 1,
    marginRight: isTranscriptVisible ? "0px" : `-${responsiveWidth}`, // Use width here
    transition: "margin .5s ease-in-out"
  };

  return (


    
    <div style= {{display: 'flex',  height: '100vh', flexDirection: 'row', overflow: 'auto'}}>
                <Joyride
            steps={steps}
            run={run}
            continuous={true}
            showSkipButton={true}
            styles={{
              options: {
                zIndex: 10000,
              },
            }}

          />
    <div style={containerStyle}>
      {isProcessing && <div className="buffer-wheel"></div>}

      <RepeatButton className='joyride-repeat-button' setIsSpeaking={setIsSpeaking} />
      
     {/* AppBar to hold Pause and Toggle buttons */}
    <AppBar position="fixed" style={{ top: 'auto', bottom: 0 , zIndex: 2000}}>
      <Toolbar>
        {/* Pause Button */}

 
  <FormControlLabel className='joyride-transcript-switch' control={<Switch checked={isTranscriptVisible} onChange={handleChange} color='default' />} label="View Transcript" />
      </Toolbar>
    </AppBar>

    {/* ... */}
<ArgumentCoach isProcessing={isProcessing} setIsProcessing={setIsProcessing} caseId={caseId} party={party} allMessages={allMessages} />

      <button
          className={`voice-button ${listening ? 'listening' : ''}`}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onClick={() => console.log('Speech mode activated')}
          disabled={isProcessing}
        >
          {listening ? <span>Listening...<span className="ellipsis"></span></span> : "Press and hold to speak"}
        </button>

    <Button variant="contained" onClick={onExitButtonClick} color="error" style={{ zIndex: 5000, position: 'absolute', right: '30px', top: '30px' }} >
  Exit
</Button>

<CenteringWrapper >
  <GridContainer>
    {justices.map((justice, index) => {
      // Determine the grid column based on the justice's index
      const isTopRow = index < 4;
      const gridColumn = isTopRow ? `${index * 2 + 3} / span 2` : `${(index - 4) * 2 + 2} / span 2`;

      // Here, we choose which component to render based on whether the justice is currently speaking.
      const AvatarComponent = isSpeaking === justice ? SpeakingAvatar : NotSpeakingAvatar;

      return (
        
        <div key={index} style={{ gridColumn, alignContent: "center", alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "column", paddingTop: '30%' }}>
          <AvatarComponent
            alt={justice}
            src={`/justpics/${justice}.png`}
            sx={{
              width: '100px', // Or any specific size
              height: '100px', // Or any specific size
              borderRadius: '50%',
              alignContent: "center",
              // Note: No need for boxShadow and transform here as they are handled by the styled components
            }}
          />
          <Typography 
            variant="caption" 
            display="block" 
            gutterBottom 
            sx={{ width: 140, textAlign: 'center', fontWeight: 'bold', marginTop: 1.25, fontSize: 13 }}
          >
            {justice}
          </Typography>
        </div>
      );
    })}
  </GridContainer>

</CenteringWrapper>

</div>

<LiveTranscript messages={messages} isTranscriptVisible={isTranscriptVisible} isLoading={isLoading} className='joyride-live-transcript'/>

    <GavelModal onGavelClick={onGavelClick} />

    </div>

  );
};

export default JusticeAvatars;