import { useEffect } from 'react';

const useFetchCourtData = (heardByIdentifier, setJustices, initializeAudioCapture) => {
  useEffect(() => {
    const fetchCourtData = async () => {
      try {
        const response = await fetch('/court_ids.json', {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        });
        
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        
        const data = await response.json();
        const selectedCourt = data.courts.find((court) => court.identifier === heardByIdentifier);
        if (selectedCourt) {
          setJustices(selectedCourt.members); // Set the justices for the current court
        } else {
          console.log('No court found for the given identifier:', heardByIdentifier);
        }
        
      } catch (error) {
        console.error('Fetch error:', error);
      }
    };

    fetchCourtData();
    initializeAudioCapture();
  }, [heardByIdentifier, setJustices, initializeAudioCapture]);
};

export default useFetchCourtData;
