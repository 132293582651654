import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function useResponsiveWidth() {
  const theme = useTheme();

  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isMd = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isLg = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
  const isXl = useMediaQuery(theme.breakpoints.up('xl'));

  let width;
  if (isXs) {
    width = '0px'; // Full width on extra-small screens
  } else if (isSm) {
    width = '300px'; // Half width on small screens
  } else if (isMd) {
    width = '400px'; // Custom width on medium screens
  } else if (isLg) {
    width = '500px'; // Original width on large screens
  }
    else if (isXl) {
        width = '600px'; // Original width on extra-large screens
    }

  return width;
}

export default useResponsiveWidth;