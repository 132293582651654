import React from 'react';
import { Card, CardContent, Typography, CardActionArea } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function CourseCard({ course }) {
    const navigate = useNavigate();
    const CourseID = course.id;

    return (
        
        <Card variant='outlined' sx={{marginTop: "250px", width: "270px", height: "285px" }}>
            <CardActionArea onClick={() => navigate(`/courses/${CourseID}`, {state: {course: course}})} sx={{height: "100%"}}>
            <CardContent>
                <Typography variant="h5">{course.name}</Typography>
                <Typography> {course.description}</Typography>
            </CardContent>
            </CardActionArea>
        </Card>
    );
}

export default CourseCard;
