import React from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';

function ProfessorSidebar() {
  return (
    <Drawer
      variant="permanent"
      open={true}
      sx={{  
        width: 200,   // width of the Drawer
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: 200,  // width of the Drawer's content
          boxSizing: 'border-box',
          //borderRight: 'none',  // remove the border if you prefer
          backgroundColor: '#fff',  // a darker background color for better contrast
          color: '#ffffff',
          zIndex: 1000
          //top: '64px'  // adjust the top bar height
        }
      }}
    >
      <List sx={{ paddingTop: '100px'}}>
        <ListItemButton component={Link} to={`/case-library`}>
          <ListItemText primary="Case Library" primaryTypographyProps={{ color: '#333', fontWeight: 900}} />
        </ListItemButton>
        <ListItemButton component={Link} to={`/saved-transcripts`}>
            <ListItemText primary="Transcripts" primaryTypographyProps={{color: '#333', fontWeight: 900}} />
        </ListItemButton>
        <ListItemButton component={Link} to={`/courses`}>
            <ListItemText primary="Courses" primaryTypographyProps={{color: '#333', fontWeight: 900}} />
        </ListItemButton>

        

        {/* Add more ListItemButton components for additional sidebar items */}
      </List>
    </Drawer>
  );
}

export default ProfessorSidebar;
