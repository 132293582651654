import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import TopBar from './TopBar';

const TopProgressBar = ({ progress }) => {
  return (
    <div>
    <LinearProgress variant="determinate" value={progress} sx={{zIndex: 1500}} />

    <TopBar />
    </div>

  );
};

export default TopProgressBar;
