import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import TopBar from './TopBar';
import Button from '@mui/material/Button';
import EventFormModal from './CalForm';
import CustomToolbar from './CustomCalTB';


const localizer = momentLocalizer(moment);

const MyCalendar = () => {
    const [events, setEvents] = useState([]);
    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleSaveEvent = (newEvent) => {
        // Update events state and backend logic here
    };

    return (
        <div>
        <TopBar />
            
            <EventFormModal 
                open={openModal} 
                handleClose={handleCloseModal} 
                onSave={handleSaveEvent} 
            />
        <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 500, marginTop: '10%', marginLeft: '15%', marginRight: '15%' }}
            components={{
                toolbar: (props) => <CustomToolbar {...props} onOpenModal={handleOpenModal} />
            }}
        />
        </div>
    );
}

export default MyCalendar;
