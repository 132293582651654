
let chunkQueue = [];

export const handlePreloadedMessages = (data, setMessages) => {
    if (data) {
        if (Array.isArray(data)) {
            const newMessages = data.map(data => ({
                sender: data.role,
                content: data.message,
            }));
            setMessages(prevMessages => [...prevMessages, ...newMessages]);
        }
    }

};


export const bufferMessageChunk = (data, setMessages, messages) => {
    // use queue to buffer chunks

      if (data.is_first_chunk) {
          chunkQueue = [];
      }

      chunkQueue.push(data);
      addChunk(setMessages, messages);

  };

export const addChunk = (setMessages, messages) => {
    const textChunk = chunkQueue.shift();
    if (textChunk.is_first_chunk) {
    handleFirstChunk(textChunk, setMessages);
    } else {  
    handleContinuationChunk(textChunk, setMessages);
    }
}

export const handleFirstChunk = (textChunk, setMessages) => {
  
            const newMessage = {
            sender: textChunk.role || 'ai',
            content: textChunk.new_chunk,
            };
            setMessages(prevMessages => [...prevMessages, newMessage]);
        }
export const handleUserMessage = (textChunk, setMessages) => {

    const newMessage = {
    sender: textChunk.role || 'human',
    content: textChunk.new_chunk,
    };
    setMessages(prevMessages => [...prevMessages, newMessage]);
}
  
export const handleContinuationChunk = (textChunk, setMessages) => {
    setMessages(prevMessages => {
        const lastMessageIndex = prevMessages.length - 1;
        if (lastMessageIndex >= 0) {
            const lastMessage = prevMessages[lastMessageIndex];
            const updatedMessage = {
                ...lastMessage,
                content: `${lastMessage.content} ${textChunk.message || textChunk.new_chunk}`,
            };
            // Create a new array with the updated message
            return [
                ...prevMessages.slice(0, lastMessageIndex),
                updatedMessage,
                ...prevMessages.slice(lastMessageIndex + 1),
            ];
        }
        return prevMessages; // Return original state if no last message found
    });
}