import { createContext, useContext, useEffect, useState } from 'react';
import { fetchData } from './api';

const TranscriptContext = createContext();

export const TranscriptProvider = ({ children }) => {
  const [savedTranscripts, setSavedTranscripts] = useState([]);

  const fetchTranscripts = () => {
    fetchData('/api/getTranscripts', {
      credentials: 'include',
    })
    .then(res => res.json())
    .then(data => {
      setSavedTranscripts(data);
    });
  };

  useEffect(() => {
    fetchTranscripts();
  }, []);

  const saveTranscript = (transcript, caseName, role) => {
    fetchData('/api/saveTranscript', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({content: transcript, caseName: caseName, role: role}),
      credentials: 'include',
    })
    .then(response => response.json())
    .then(data => {
      if(data.message === 'Transcript saved!') {
        fetchTranscripts();  // Refetch transcripts after saving
      }
    });
  };

  const deleteTranscriptFromState = (id) => {
    setSavedTranscripts(prev => prev.filter(transcript => transcript.id !== id));
  };

  return (
    <TranscriptContext.Provider value={{ savedTranscripts, saveTranscript, deleteTranscriptFromState }}>
      {children}
    </TranscriptContext.Provider>
  );
};    

export const useTranscripts = () => {
  return useContext(TranscriptContext);
};
