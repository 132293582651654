import React from 'react';
import { BrowserRouter as Router, Route, useNavigate, Routes} from "react-router-dom";
import './App.css';
import { TranscriptProvider } from './TranscriptContext';
import AuthPage from './AuthPage';
import { AuthProvider } from './AuthContext';
import ProtectedRoute from './ProtectedRoute';
import Dashboard from './Dashboard';
import SearchResultsPage from './SearchResultsPage';
import ConversationUI from './ConversationUI';
import ConversationUI_DC from './ConversationUI_DC';
import CaseLibrary from './CaseLibrary';
import NewCaseDetail from './NewCaseDetail';
import LiveArgument from './LiveArgument';
import SavedTranscriptsList from './TranscriptsList';
import SignUp from './NewSignUp';
import NewSignIn from './NewSignIn';
import TranscriptPage from './TranscriptPage'; 
import SingleTranscriptView from './SingleTranscriptView';
import Button from '@mui/material/Button';
import MyCalendar from './MyCalendar';
import ProfessorDashboard from './ProfessorDashboard';
import CoursesPage from './CoursesPage';
import CourseHome from './CourseHome';
import CourseStudents from './CourseStudents';
import CourseAssignments from './CourseAssignments';
import Box from '@mui/material/Box';
import MyCases from './MyCases';
import NewCase from './NewCase';
import MyCourts from './MyCourts';


function LandingPage() {
  const navigate = useNavigate();
  return (
    <div className="App">
                    <Box
                component="img"
                sx={{
                  m: 4, // Margin, adjust as needed

                }}
                src={`${process.env.PUBLIC_URL}/ArgoLogo.png`} // Path to your logo in the public folder
                alt="Your Logo"
              />
      <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            style={{ width: '20%' }}
                            onClick={() => navigate('/dashboard')}
                        >
                            Log In
                        </Button>
    </div>
  );
}




function App() {


  return (
    <AuthProvider>
      <TranscriptProvider>
        <Router>
          <Routes>
            <Route path="/chat" element={<ConversationUI />} />
            <Route path="/case-library" element={<CaseLibrary />} />
            <Route path="/auth" element={<AuthPage />} />
            <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
            <Route path="/search/:query" element={<ProtectedRoute><SearchResultsPage /></ProtectedRoute>} />
            <Route path="/calendar" element={<ProtectedRoute><MyCalendar /></ProtectedRoute>} />
            <Route path="/case/:id" element={<NewCaseDetail />} />
            <Route path="/prof-dashboard" element={<ProtectedRoute><ProfessorDashboard /></ProtectedRoute>} />
            <Route path="/courses" element={<ProtectedRoute><CoursesPage /></ProtectedRoute>} />
            <Route path="/courses/:id" element={<ProtectedRoute><CourseHome /></ProtectedRoute>} />
            <Route path="/courses/:id/students" element={<ProtectedRoute><CourseStudents /></ProtectedRoute>} />
            <Route path="/courses/:id/assignments" element={<ProtectedRoute><CourseAssignments /></ProtectedRoute>} />
            <Route path="/live-argument/:id/:role/:mode" element={<LiveArgument />} />
            <Route path="/live-argument-dc/:caseId/:party/:mode" element={<ConversationUI_DC />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/sign-in" element={<NewSignIn />} />
            <Route path="/transcript" element={<TranscriptPage />} />
            <Route path="/saved-transcripts" element={<ProtectedRoute><SavedTranscriptsList /></ProtectedRoute>} />
            <Route path="/my-cases" element={<ProtectedRoute><MyCases /></ProtectedRoute>} />
            <Route path="/new-case" element={<ProtectedRoute><NewCase /></ProtectedRoute>} />
            <Route path="/my-courts" element={<ProtectedRoute><MyCourts /></ProtectedRoute>} />

            <Route path="/saved-transcripts/:id" element={<ProtectedRoute><SingleTranscriptView /></ProtectedRoute>} />
            <Route path="/" element={<LandingPage />} />
          </Routes>
        </Router>
      </TranscriptProvider>
    </AuthProvider>
  );
}

        

export default App;
