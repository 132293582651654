import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import { fetchData } from './api';
import { ButtonBase } from '@mui/material';

const GavelModal = ({ onGavelClick }) => {
  const [isOpen, setIsOpen] = useState(true); // Keep the modal open by default when the page loads
  const [gavelStyle, setGavelStyle] = useState({}); // For gavel animation
  const [isSwinging, setIsSwinging] = useState(false);
  let audio = new Audio('/gavel_sound.mp3');


  const handleInternalGavelClick = () => {
    onGavelClick();
    audio.play();
    fetchData('/api/clear-conversation-history', { method: 'POST' })
      .then(console.log('Conversation history cleared'))
      .then(res => res.json())

    
    // Trigger gavel press animation
    setGavelStyle({ transform: 'translateY(10px)', transition: 'all 0.2s ease-in-out' });
    
    // Trigger gavel swing animation
    setIsSwinging(true);
  
    
    
  
    // Close modal and reset animations after they complete
    setTimeout(() => {
      setGavelStyle({});
      setIsSwinging(false);
      setIsOpen(false);
    }, 500);
    
  };
  

  return (
    <Modal
      open={isOpen}
      style={{zIndex: 5000}}
      // Not allowing a close by clicking outside the modal
      // onClose={() => setIsOpen(false)}
    >
      <div style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        padding: '20px',
        width: '400px',  // width of the square
        height: '400px',  // height of the square
        display: 'flex',  // using flexbox for center alignment
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        //position: 'relative',
        boxShadow: '0px 4px 16px 2px rgba(0, 0, 0, 0.9)', // Add some shadow for depth
        borderRadius: '12px', // Rounded corners
        background: 'linear-gradient(145deg, #ffffff, #f0f0f0)', // Add a gradient background
      }}>
        <h2 style={{
            textAlign: 'center',
            position: 'absolute',
            top: '6%',
            width: '75%'}}>Click the gavel when you are ready to begin</h2>
        <ButtonBase onClick={handleInternalGavelClick} disableRipple className='my-start-button' >
        <img 
            src="/gavel-icon.png" 
            alt="Gavel" 
            style={{ 
              ...gavelStyle,
              filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
              width: '100px',
              height: '100px',
              transformOrigin: 'bottom left' // This is the pivot for the rotation
            }}
            className={isSwinging ? 'swing-animation' : ''}
          />

        </ButtonBase>
      </div>
    </Modal>
  );
};

export default GavelModal;
