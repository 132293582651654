// Assuming you've already initialized audioCtx outside the component somewhere
const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
console.log("Audio context:", audioCtx);
let audioQueue = [];
let lastBufferSource = null;
// This will store the latest buffer and Justice for replay
export let latestAudioInfo = {
  buffer: null,
  justice: ''
};

export function base64ToArrayBuffer(base64) {
  const binaryString = atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return bytes.buffer;
}


export const playAudioChunk = async (chunk, setIsSpeaking) => {
  if (chunk.byteLength === 0) {
    console.error("Error: Received empty audio chunk.");
    return; // Exit the function if the chunk is empty
  }

  try {

      const audioData = base64ToArrayBuffer(chunk); // Convert Base64 to ArrayBuffer
            const audioBuffer = createAudioBuffer(audioData);
  
      audioQueue.push(audioBuffer);
    console.log("Audio queue length:", audioQueue.length);
    if (audioQueue.length > 0 && !lastBufferSource) {
      // Start playback if it's not already ongoing
      playNextBuffer(setIsSpeaking);
      setIsSpeaking(chunk.voice); // Set isSpeaking back to no one speaking
    }
  } catch (error) {
    console.error("Error decoding audio data:", error);
  }
};

function createAudioBuffer(arrayBuffer) {
  const data = new DataView(arrayBuffer);
  const length = arrayBuffer.byteLength / 2;
  const audioBuffer = audioCtx.createBuffer(1, length, 24000);
  const channelData = audioBuffer.getChannelData(0);
  for (let i = 0; i < length; i++) {
      const offset = i * 2;
      if (offset + 1 >= data.byteLength) {
          console.error('Offset exceeds buffer size at sample', i);
          break;
      }
      const sample = data.getInt16(offset, true);
      channelData[i] = sample / 32768.0;
  }
  return audioBuffer;
}

let nextPlayTime = audioCtx.currentTime; // Initialize with the current time

const playNextBuffer = (setIsSpeaking) => {
  if (audioQueue.length === 0) {
    lastBufferSource = null;
    setIsSpeaking('');
    // No more buffers to play
    return;
  }

  // Get the next buffer from the queue
  const audioBuffer = audioQueue.shift();

  // Create a new buffer source for this buffer
  const source = audioCtx.createBufferSource();
  source.buffer = audioBuffer;

  // Connect the source to the context's destination (the speakers)
  source.connect(audioCtx.destination);

  // Schedule the source to start in the future
  source.start(nextPlayTime);

  // Update nextPlayTime for the next buffer
  nextPlayTime += audioBuffer.duration;




    // Setup the onended event listener to play the next buffer
    source.onended = () => {
      // This ensures we continue playback as soon as the current one ends
      playNextBuffer(setIsSpeaking);
    };
    lastBufferSource = source

  };









// This is the function to replay the latest audio
export const replayAudio = (setIsSpeaking) => {
if (latestAudioInfo.buffer) {
    playNextBuffer(latestAudioInfo.buffer, () => {
      // After the audio has finished playing
      setIsSpeaking('');  // Set isSpeaking back to no one speaking
    });
 
// Set the justice's name so the correct avatar lights up
    setIsSpeaking(latestAudioInfo.justice);
  } else {
    console.log("No audio to replay");
  }
};
