import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import TopBar from './TopBar';
import  Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

const CaseLibrary = () => {
    const [cases, setCases] = useState([]);

    useEffect(() => {
        fetch('/case_library_with_formatted_messages.json', {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            setCases(data);
            console.log("Identifiers:", data.map(caseItem => caseItem.heard_by_identifier));
        });

    }, []);

    return (
        <div style={{display: 'flex', flexDirection: 'row'}} >
        <TopBar />
        <div style={{backgroundColor: '#F7F7F7',  display: 'flex', height: '100vh', flexDirection: 'column', flexGrow: 1, marginTop: '64px', padding: '64px'}}>
            
        <div style={{fontWeight: '500', fontFamily: 'Segoe UI', marginBottom: '2.25rem', marginTop: '-1.5rem', marginLeft: '-1.25rem', fontSize: '20px'}}>Case Library</div>

        <div className="case-library">
          
            <div className="case-list">
                
                {cases.map((caseItem, index) => (
                    <Link 
                        key={index} 
                        to={{ 
                            pathname: `/case/${caseItem.name}`}}
                            state= {{ heardByIdentifier: caseItem.heard_by_identifier }}
                        

                        className="case-link"  
                    >
                        <div className="case">
                            <h2>{caseItem.name}</h2>
                            <p>Supreme Court</p>
                            <p>{caseItem.year}</p>
                            
                        </div>
                    </Link>
                ))}
            </div>


        </div>
        </div>
        </div>
    );
}
    
export default CaseLibrary;
