import React from 'react';
import { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import TopBar from './TopBar';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';


const NewCaseDetail = () => {
    const [caseDetail, setCaseDetail] = useState();
    let { id } = useParams();  // This id should correspond to the case name
    const [party, setParty] = React.useState('');
    const [mode, setMode] = React.useState('');
    const [error, setError] = React.useState(false);
    const [partyHelperText, setPartyHelperText] = React.useState(' ');
    const [modeHelperText, setModeHelperText] = React.useState(' ');
    const navigate = useNavigate();
    const location = useLocation();
    const heardByIdentifier = location.state?.heardByIdentifier;
    console.log("Location state:", location);
    console.log("Heard by identifier:", heardByIdentifier);

  
    const handlePartyChange = (event) => {
      setParty(event.target.value);
      setPartyHelperText(' ');
      setError(false);
    };
    const handleModeChange = (event) => {
        setMode(event.target.value);
        setModeHelperText(' ');
        setError(false);
    };
    const handleSubmit = (event) => {
        event.preventDefault();
    
        if (party === null || party === '') {
            setPartyHelperText('Please select an option.');
            setError(true);
        } else if (mode === null || mode === '') {
            setModeHelperText('Please select an option.');
            setError(true);
        } else {
            navigate(`/live-argument/${id}/${party}/${mode}`, {
                state: { heard_by_identifier: heardByIdentifier }
              });
        }
      };


    useEffect(() => {
        console.log(id);
        fetch('/case_library_with_formatted_messages.json', {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then((response) => {
            if (!response.ok) { // If HTTP status is not OK
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
        })
        .then((data) => {
            const selectedCase = data.find(caseItem => caseItem.name === id);
            setCaseDetail(selectedCase);
        })
        .catch((error) => {
            console.error('Fetch error:', error);
        });
    }, [id]);

    if (!caseDetail) {
        return <div>Loading...</div>;  // Or some loading spinner
    }





    return (
        // Render TopBar component
    <div>
        <TopBar />
        <div style={{height: '100px'}}></div> {/* This is a spacer to push the content below the top bar */}
        <div className='l-12col-container'>
            <div className='l-col-xs-12 l-col-md-9'>
                <div className='c-panel c-card'>
                    <div className='c-panel_content'>
                        <h1 className='u-mb-hr'>{id}</h1>
                            <p>({caseDetail.year})</p>
                        <div className='case-sections'>
                            <div className='p-case-subsection'>
                            <h2 className='u-bb-3px'>Facts of the Case</h2>
                                <p dangerouslySetInnerHTML={{__html: caseDetail.facts_of_the_case}}></p>
                            </div>
                            <div className='p-case-subsection'>
                            <h2 className='u-bb-3px'>Question Presented</h2>
                                <p dangerouslySetInnerHTML={{__html: caseDetail.question}}></p>
                            </div>
                            
                            <div className='p-case-subsection'>
                            <h2 className='u-bb-3px'>Argument of Petitioner ({caseDetail.petitioner})</h2>
                                <p>
                                    <ul>
                                    {caseDetail.petitioner_arguments_out.map((arg, index) => (
                                <li key={arg.argument_id} className='u-mb-hr'>
                                    <strong>{arg.title}:</strong> {arg.description}
                                </li>
                            ))}
                                    </ul>
                                </p>
                            </div>
                            <div className='p-case-subsection'>
                            <h2 className='u-bb-3px'>Argument of Respondent ({caseDetail.respondent})</h2>
                                <p> 
                                    <ul>
                                    {caseDetail.respondent_arguments_out.map((arg, index) => (
                                <li key={arg.argument_id} className='u-mb-hr'>
                                    <strong>{arg.title}:</strong> {arg.description}
                                </li>
                            ))}
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='l-col-xs-12 l-col-md-3 '>
                <div className='c-arg-sets c-card u-bs-2s'>

                    
                <form onSubmit={handleSubmit}>

                    <div>
                        <h2 className='u-mb-hr'>Argument Settings</h2>
                            <div className='c-set-sections'>

                                <h3 className='u-ul-1r'>Role</h3>
      <FormControl sx={{ m: 0 }} error={error} variant="standard">
        <RadioGroup
          aria-labelledby="role-radios"
          name="party-group"
          value={party}
          onChange={handlePartyChange}
          sx={{ marginRight: '0px' }}
        >
          <FormControlLabel value="petitioner" control={<Radio />}  label={<h4>Petitioner, {caseDetail.petitioner}</h4>}> </FormControlLabel>
          <FormControlLabel value="respondent" control={<Radio />}  label={<h4>Respondent, {caseDetail.respondent}</h4>}> </FormControlLabel>
        </RadioGroup>
        <FormHelperText>{partyHelperText}</FormHelperText>
      </FormControl>
      <h3 className='u-ul-1r u-mt-1r'>Chat Mode</h3>
      <FormControl sx={{ m: 0 }} error={error} variant="standard">
        <RadioGroup
            aria-labelledby="mode-radios"
            name="mode-group"
            value={mode}
            onChange={handleModeChange}
            sx={{ marginRight: '0px' }}
        >
            <FormControlLabel value="Voice" control={<Radio />}  label={<h4>Voice</h4>}> </FormControlLabel>
            <FormControlLabel value="Text" control={<Radio />}  label={<h4>Text</h4>}> </FormControlLabel>
        </RadioGroup>
        <FormHelperText>{modeHelperText}</FormHelperText>
        </FormControl>

                            </div>
                    </div>

                    <Button sx={{ mt: 1, mr: 1, top: 100, width: '100%' }} type="submit" variant="contained">
          Begin Argument
        </Button>
        </form>

                </div>
            </div>
    </div>
</div>
);
};

export default NewCaseDetail;