import { createContext, useContext, useState, useEffect } from 'react';
import { fetchData } from './api';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Used to prevent flashing of login screen on app startup

  useEffect(() => {
    // Check if user is already authenticated when app initializes
    const checkAuthenticatedStatus = async () => {
      try {
        const response = await fetchData('/api/check_authenticated', {
          method: 'GET',
          credentials: 'include', // Important, as we're using cookies
        });

        if (response.ok) {
          const data = await response.json();
          if (data.isAuthenticated) {
            setIsAuthenticated(true);
          }
        }
      } catch (error) {
        console.error('Error checking authentication status:', error);
      }
      setIsLoading(false);
    };

    checkAuthenticatedStatus();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const logout = async () => {
    try {
      const response = await fetchData('/api/logout', {
        method: 'POST',
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Invalidate frontend session and user data
      setIsAuthenticated(false);
    } catch (error) {
      console.error("Error during logout request:", error);
    }
  };

  const value = { isAuthenticated, setIsAuthenticated, logout };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}
