import { useParams, useLocation } from "react-router-dom";
import ConversationUI from "./ConversationUI";
import JusticeAvatars from "./JusticeAvatars";
import React, { useEffect, useState } from "react";

const LiveArgument = () => {
    let { id, role, mode } = useParams();
    const location = useLocation();
    const heardByIdentifier = location.state?.heard_by_identifier;
    console.log("Location state:", location);
    const [isCustom, setIsCustom] = useState(false);
    const [caseDetails, setCaseDetails] = useState({role: role, name: id});

    useEffect(() => {
      // Check if navigation state includes isCustom and update state accordingly
      if (location.state?.isCustom) {
        setIsCustom(location.state.isCustom);
        setCaseDetails(location.state);
      }
    }, [location.state]);

    useEffect(() => {
        if (location.state?.isCustom) {
          setIsCustom(location.state.isCustom);
        }
      }, [location.state]);

    return (
        <div className="live-argument">
            {mode === 'Voice' ? 
                <JusticeAvatars 
                    party={role} 
                    caseId={id} 
                    heardByIdentifier={heardByIdentifier} 
                    isCustom={isCustom}
                    caseDetails={caseDetails}
                /> : 
                <ConversationUI 
                    party={role} 
                    caseId={id} 
                    heardByIdentifier={heardByIdentifier} 
                    isCustom={isCustom}
                    caseDetails={caseDetails}
                />
            }
        </div>
    );
}

export default LiveArgument;
