const steps = [
    {
      target: '.joyride-live-transcript',
      content: 'To help get things started, we have preloaded the opening exchange from the real oral argument. Those statements will appear here when you begin the argument, along with all subsequent statements between you and the AI Justices.',
      title: 'Argument Transcript',
      disableBeacon: true,
      placement: 'left',
    },
    {
      target: '.joyride-transcript-switch',
      content: 'Don\'t need the transcript? You can toggle Transcript View with this switch.',
      title: 'Transcript View Switch',
    },
    {
      target: '.joyride-repeat-button',
      content: 'If you want to hear a statement again, just click this button.',
      title: 'Repeat Button',
      placement: 'bottom',
    },
    {
      target: '.joyride-fab',
      content: 'Need help? Click this button to get some tips from an AI Argument Coach.',
      title: 'Argument Coach',
    },
  
    // Add as many steps as you need
  ];

export default steps;