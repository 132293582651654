/**
 * Removes HTML tags from a given string.
 *
 * @param {string} input - The input string to remove HTML tags from.
 * @returns {string} The input string with HTML tags removed.
 */
export const removeHtmlTags = (input) => {
    return input.replace(/<\/?[^>]+(>|$)/g, "");
};

/**
 * Removes the first part of a string that is separated by a colon.
 * @param {string} input - The input string to remove the first part from.
 * @returns {string} The input string with the first part removed, or the original input string if there is no colon.
 */
export const removeJusticeName = (input) => {
    const parts = input.split(':');
    if (parts.length > 1) {
        return parts.slice(1).join(':').trim();
    }
    return input;
};

/**
 * Formats message data from a JSON string to an array of formatted messages.
 * @param {string} messages - The JSON string of messages to format.
 * @returns {Array} An array of formatted messages.
 */
export const formatMessageData = (messages) => {
    messages = JSON.parse(messages);
    console.log(Array.isArray(messages));
  
    return messages.map(message => {
      if (message.type === "constructor") {
        switch (message.id[3]) {
          case "HumanMessage":
            return {
              role: "human",
              message: message.kwargs.content
            };
          case "AIMessage":
            // Here, we apply the formatJusticeName function
            const formattedContent = formatJusticeName(message.kwargs.content);
            console.log(message.kwargs.additional_kwargs?.justice);
            const justiceAvatar = message.kwargs.additional_kwargs?.justice || "John Roberts";
            return {
              role: "ai",
              sender: "ai",
              message: formattedContent,
              avatar: justiceAvatar
            };
          default:
            return null; // or some default behavior for unrecognized types
        }
      }
      return null; // or some default behavior for unrecognized types
    }).filter(message => message !== null); // filter out null entries
  };

/**
 * Replaces the string "JUSTICE [A-Z]:" with "Justice [A-Z]:<br>" and formats the name to have the first letter capitalized.
 * @param {string} message - The message to format.
 * @returns {string} The formatted message.
 */
export const formatJusticeName = (message) => {
  return message.replace(/JUSTICE ([A-Z]+):/, (match, p1) => {
    const formattedName = p1.charAt(0) + p1.slice(1).toLowerCase();
    return `<strong>Justice ${formattedName}:</strong><br>`;
  });
};


/**
 * Replaces all occurrences of "Justice [A-Za-z]+:" in the input string with the same text wrapped in <strong> tags.
 * 
 * @param {Object} data - The input data object.
 * @param {string} data.message - The input string to be processed.
 * @returns {string} The input string with all occurrences of "Justice [A-Za-z]+:" wrapped in <strong> tags.
 */
export const boldJusticeName = (data) => {
  if (!data || !data.message) {
    return ''; // or some other appropriate default if there's no message
  }
  // This regular expression finds the pattern "Justice [A-Za-z]+:" in the message.
  // It matches "Justice" followed by a space, a name that can be in regular case (not just uppercase), and a colon.
  return data.message.replace(/(Justice [A-Za-z]+:)/g, '<strong>$1</strong><br>');
};