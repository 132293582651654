import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import AddStudentModal from './AddStudentsModal'; // Component for adding students
import { useParams } from 'react-router-dom';
import CourseHome from './CourseHome';

function CourseStudents() {
    let { id } = useParams(); // Get the course ID from the URL
    const [students, setStudents] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const fetchStudents = () => {
        fetch(`https://api.arguendoai.com/api/courses/${id}/students`)
            .then(response => response.json())
            .then(data => setStudents(data))
            .then(console.log(students))
            .catch(error => console.error('Error:', error));
    };

    useEffect(() => {
        fetchStudents();
    }, [id]);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleStudentAdded = () => {
        fetchStudents();
        setIsModalOpen(false);
    };

    return (
        <div>
            <CourseHome />
            <div style={{marginLeft: "192px", marginRight: "24px"}}>
            <h2></h2>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {students && students.length > 0 ? students.map((student) => (
                            <TableRow key={student.id}>
                                <TableCell>{student.name}</TableCell>
                                <TableCell>{student.email}</TableCell>
                            </TableRow>
                        )) : (
                            <TableRow>
                                <TableCell>No Students</TableCell>
                                </TableRow>
                            )
                            }
                    </TableBody>
                </Table>
            </TableContainer>
            <Button onClick={handleOpenModal}>Add Student</Button>
            <AddStudentModal open={isModalOpen} onClose={handleStudentAdded} courseId={id}  />
        </div>
        </div>
    );
}
export default CourseStudents;
