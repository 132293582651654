import styled from '@mui/system/styled';
import Avatar from '@mui/material/Avatar';


export const CenteringWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    overflow: 'hidden',
  });
  
export const GridContainer = styled('div')({
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gap: '15%',
    rowGap: "10px",
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    margin: 'auto',
    padding: '15%',
  });

  export const SpeakingAvatar = styled(Avatar)({
    boxShadow: '0px 0px 0px rgba(0, 0, 0, 0.5), 0px 0px 15px rgba(25, 118, 240, 1)',
    transform: 'scale(1.1)',
  });
  
 export const NotSpeakingAvatar = styled(Avatar)({
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
    transform: 'scale(1)',
  });