import React from 'react';
import { Button } from '@mui/material';

const CustomToolbar = ({ onNavigate, label, onView, view, views, onOpenModal }) => {
    return (
        <div className="rbc-toolbar">
            <span className="rbc-btn-group">
                <button type="button" onClick={() => onNavigate('PREV')}>Back</button>
                <button type="button" onClick={() => onNavigate('NEXT')}>Next</button>
                <span className="rbc-toolbar-label">{label}</span>
                <button type="button" onClick={() => onNavigate('TODAY')}>Today</button>
            </span>
            <span className="rbc-btn-group">
                {views.map((name) => (
                    <button type="button" key={name} onClick={() => onView(name)}>
                        {name}
                    </button>
                ))}
            </span>
            <span className="rbc-btn-group">
                <Button variant="outlined" onClick={onOpenModal}>
                    Add New Event
                </Button>
            </span>
        </div>
    );
};

export default CustomToolbar;
