import React, { useState, useEffect } from 'react';
import  Button  from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import HomeTopBar from './HomeTopBar';
import ProfessorSidebar from './ProfessorSidebar';




const WelcomeComponent = () => {
    const [name, setName] = useState('');

    useEffect(() => {
        async function loadName() {
            const currentUser = await fetchCurrentUser();
            setName(currentUser.firstname);
        }

        loadName();
    }, []);

    return (
        <h1 style={{ color: '#333' }}>
            {`Welcome back, ${name}!`}
        </h1>
    );
}

async function fetchCurrentUser() {
    try {
        const response = await fetch('https://api.arguendoai.com/api/getCurrentUser', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();

        if (data.firstname) {
            return data;
        } else {
            // Handle error, maybe redirect to login page or show error message
            throw new Error('First name not found in response');
        }
    } catch (error) {
        console.error("Error fetching current user:", error);
        return '';  // Return an empty string or handle this scenario appropriately in your app
    }
}


function ProfessorDashboard() {

    const navigate = useNavigate();







    return (
        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#F7F7F7', height: '100vh' }}>
            <HomeTopBar />
            
            <div style={{ display: 'flex', flexGrow: 1, paddingTop: '64px' }}>  

                <ProfessorSidebar />

                <div style={{
                    flex: 1, 
                    display: 'flex', 
                    justifyContent: 'flex-start', 
                    alignItems: 'flex-start',
                    flexDirection: 'column'  // This changes the container arrangement from side-by-side to top-to-bottom
                }}>

                    {/* New Container Starts Here */}
                    <div style={{
                        backgroundColor: 'white',
                        width: '80%', 
                        height: '10%',  // You can adjust this for the height of the thin box
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        padding: '20px',
                        borderRadius: '5px',
                        margin: '3% 0% 0% 3%',
                    }}>
                        {/* Content of the new container starts here */}
                        <WelcomeComponent />

                        {/* Content of the new container ends here */}
                    </div>
                    {/* New Container Ends Here */}

                    <div style={{
                        backgroundColor: 'white',
                        width: '80%', 
                        height: '40%',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        padding: '20px',
                        borderRadius: '5px',
                        margin: '3% 0% 0% 3%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <h2 style={{ color: '#333', marginBottom: '20px' }}>
                            Ready to Start a New Argument?
                        </h2>

                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            style={{ width: '50%' }}
                            onClick={() => navigate('/case-library')}
                        >
                            FIND A CASE
                        </Button>

                        




                    </div>

                   
                </div>
            </div>
        </div>
    );
}
  
export default ProfessorDashboard;