import React, { useState, useRef, useEffect, forwardRef } from 'react';
import './LiveTranscript.css';
import Drawer from '@mui/material/Drawer';
import useResponsiveWidth from './ResponsiveWidth';
import Typewriter from 'typewriter-effect';

const LiveTranscript = forwardRef(({messages, isTranscriptVisible, isLoading, className}, ref) => {
    
    const messagesEndRef = useRef(null);
    const [isAIResponding] = useState(false);
    const responsiveWidth = useResponsiveWidth();







        
/*
useEffect(() => {
  console.log('Effect is running', data);
  if (data) { // assuming 'transcript' is a field in your data
    if (Array.isArray(data)) {
      // Handle array of messages
      const newMessages = data.map(data => ({
          sender: data.role,
          content: data.message,
          // Add other relevant properties here...
      }));
      setMessages(prevMessages => [...prevMessages, ...newMessages]);
  } else if (data.is_first_chunk) {
    // This is the first chunk of a new message
    // Start a new message entry with this chunk
    const newMessage = {
      sender: data.role || 'ai', 
      content: data.new_chunk, // Start with this chunk's content
    };
    // Append this new message to the messages array
    setMessages(prevMessages => [...prevMessages, newMessage]);
  } else {
    // This chunk is a continuation of the latest message
    // Append the chunk to the last message's content
    setMessages(prevMessages => {
      if (prevMessages.length === 0) {
        // Edge case: There's no message to append to, which should not happen
        // but handle it by starting a new message anyway
        return [{
          sender: data.role || 'ai',
          content: data.message || data.new_chunk,
        }];
      } else {
        // Normally, append to the last message
        const updatedMessages = [...prevMessages];
        const lastMessageIndex = updatedMessages.length - 1;
        const messageContent = data.message || data.new_chunk;
        updatedMessages[lastMessageIndex] = {
          ...updatedMessages[lastMessageIndex],
          content: updatedMessages[lastMessageIndex].content + " " + messageContent,
        };
        console.log(data.new_chunk)
        return updatedMessages;
      }
  });
}
  }
}, [data]); // This will run every time the 'data' prop changes
*/


    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);
    

    // AI Skeleton
const AISkeleton = () => {
    return (
      <div className="t-message-group bg-gray-50 text-gray-800">
        <div className="t-message-wrapper">
          <div className="t-avatar-section">
            <div className="t-skeleton-avatar bg-gray-300"></div>
          </div>
          <div className="t-skeleton-message-content bg-gray-300"></div>
        </div>
      </div>
    );
  };
  
  // Human Skeleton
  const HumanSkeleton = () => {
    return (
      <div className="t-message-group bg-white text-gray-800">
        <div className="t-message-wrapper">
          <div className="t-avatar-section">
            <div className="t-skeleton-avatar bg-gray-300"></div>
          </div>
          <div className="t-skeleton-message-content bg-gray-300"></div>
        </div>
      </div>
    );
  };
  
 
  const containerStyle = {
    width: `${responsiveWidth}`, // Ensure responsiveWidth is a numeric value
    margin: '0',
    padding: '0px',
    overflow: 'hidden',
    backgroundColor: 'transparent', // 'transparent' should be a string
    zIndex: 1,
    height: `calc(100vh - 64px)`,
    display: 'flex', // 'flex' should be a string
    position: 'relative', // 'relative' should be a string
    flexDirection: 'row', // 'row' should be a string
  };

    return (
      
    <div ref={ref} style={containerStyle} className={className} >
    <Drawer anchor="right" open={isTranscriptVisible} SlideProps={{timeout: 500, easing: 'ease-in-out'}} variant='persistent'   sx={{
// default styles applied to all sizes
height: 'calc(100% - 64px)',
width: {
  xs: '0px', // widths on extra-small devices
  sm: '300px', // widths on small devices
  md: '400px', // widths on medium/large screens
  lg: '500px', // widths on large/extra-large screens
  xl: '600px', // widths on extra-large screens
},
flexShrink: 0,
borderLeft: 'none',
'& .MuiDrawer-paper': {
  boxSizing: 'border-box',
  height: 'calc(100% - 64px)',
  borderLeft: 'none',
  // responsive widths based on screen sizes
  width: {
    xs: '0px',
    sm: '300px',
    md: '400px', // widths on medium/large screens
    lg: '500px', // widths on large/extra-large screens
    xl: '600px', // widths on extra-large screens
  },
},
}}>
        
            <div className="t-messages-container" style={{backgroundColor: "#F7F7F7", padding: "10px"}}>
                {isLoading ? (
                    <>
                        <AISkeleton />
                        <HumanSkeleton />
                        <AISkeleton />
                    </>
                    ) : (
                messages.map((message, index) => (
                  <div
                      key={index}
                      className={`${message.sender === 'ai' ? 't-message-group-ai t-bg-blue-text-white text-gray-800' : 't-message-group-user t-bg-white text-gray-800'}`}
                      style={{ overflowWrap: 'normal', position: 'relative' }}
               
                  >
         
                        <div className="t-message-wrapper">
                        
                            <div 
                                className="t-message-content"                                
                           
                              dangerouslySetInnerHTML={{ __html: message.content }}          
                              >                      
                                
                        </div>
                        </div>
                    </div>
                ))
                    )}
{isAIResponding && <AISkeleton />}
                                    <div ref={messagesEndRef} />
            </div>
    
           
            
            
            </Drawer>
            </div>


    );

}
);

export default LiveTranscript;
    